export default {
  1: [
    {
      title: '关于加强房地产中介机构及从业人员备案信息管理有关事项的通知',
      downUrl:
        '/rent-house/notice/关于加强房地产中介机构及从业人员备案信息管理有关事项的通知.pdf'
    },
    {
      title: '南宁市支持住房租赁市场发展试点财政专项资金管理办法',
      downUrl:
        '/rent-house/notice/（主动公开）南住建规〔2021〕3号  关于印发《南宁市支持住房租赁市场发展试点财政专项资金管理办法》的通知 （市场科） （联合发文）.pdf'
    },
    {
      title: '《南宁市房屋租赁合同示范文本》(2019版)',
      downUrl: '/rent-house/notice/《南宁市房屋租赁合同示范文本》(2019版) .doc'
    },
    {
      title:
        '南住建函[2019]2700号南宁市住房和城乡建设局关于进一步加强房地产经纪机构和住房租赁企业备案管理工作函',
      downUrl:
        '/rent-house/notice/南住建函[2019]2700号  南宁市住房和城乡建设局关于进一步加强房地产经纪机构和住房租赁企业备案管理工作的函.pdf'
    },
    {
      title: '租赁企业线上办理房屋租赁备案业务流程及收件',
      downUrl:
        '/rent-house/notice/租赁企业线上办理房屋租赁备案业务流程及收件.docx'
    },
    {
      title: '经纪机构线上办理房屋租赁备案业务流程及收件',
      downUrl:
        '/rent-house/notice/经纪机构线上办理房屋租赁备案业务流程及收件.docx'
    }
  ],
  2: [
    {
      title: '南宁市房地产经纪机构和住房租赁企业备案申请表',
      downUrl:
        '/rent-house/notice/南宁市房地产经纪机构和住房租赁企业备案申请表.docx'
    },
    {
      title: '南宁市房屋租赁备案申请表',
      downUrl: '/rent-house/notice/南宁市房屋租赁备案申请表.docx'
    }
  ],
  3: [
    {
      title: '租赁系统操作手册（电脑版企业端）',
      downUrl: '/rent-house/notice/租赁系统操作手册（电脑版企业端）.pdf',
      time: '2022-06-07'
    },
    {
      title: '租赁系统操作手册（电脑版个人端）',
      downUrl:
        '/rent-house/notice/(WEB端个人版)南宁市住房租赁服务监管平台使用说明书.pdf',
      time: '2022-06-07'
    },
    {
      title: '租赁系统操作手册（手机版企业端）',
      downUrl: '/rent-house/notice/租赁系统操作手册（手机版企业端）.pdf',
      time: '2022-06-07'
    },
    {
      title: '租赁系统操作手册（手机版个人端）',
      downUrl:
        '/rent-house/notice/(移动端个人版)南宁市住房租赁服务监管平台使用说明书.pdf',
      time: '2022-06-07'
    },
    {
      title: '市住房租赁服务监管平台房源核验码操作流程（电脑端）',
      downUrl:
        '/rent-house/notice/市住房租赁服务监管平台房源核验码操作流程（电脑端）.pdf'
    },
    {
      title: '市住房租赁服务监管平台房源核验码操作流程（手机端）',
      downUrl:
        '/rent-house/notice/市住房租赁服务监管平台房源核验码操作流程（手机端）.pdf'
    },
    {
      title: '租赁企业线上办理房屋租赁备案业务流程及收件',
      downUrl:
        '/rent-house/notice/租赁企业线上办理房屋租赁备案业务流程及收件.docx'
    },
    {
      title: '经纪机构线上办理房屋租赁备案业务流程及收件',
      downUrl:
        '/rent-house/notice/经纪机构线上办理房屋租赁备案业务流程及收件.docx'
    },
    {
      title: '代理合同操作视频',
      downUrl: '/rent-house/notice/代理合同操作视频.mp4'
    },
    {
      title: '企业注册操作视频',
      downUrl: '/rent-house/notice/企业注册操作视频.mp4'
    },
    {
      title: '企业变更操作视频',
      downUrl: '/rent-house/notice/企业变更操作视频.mp4'
    },
    {
      title: '企业注册操作手册(电脑版)',
      downUrl: '/rent-house/notice/企业注册操作手册.docx'
    },
    {
      title: '企业注册操作手册(手机版)',
      downUrl: '/rent-house/notice/企业注册操作手册(移动端).docx'
    }
  ]
}
