<template>
  <div style='height: 100%'>
    <el-row justify='space-between'>
      <el-col :span='4' class='left-warp' :style="{ height: domHeight + 'px' }">
        <ul>
          <li
            v-for='(m, index) in leftMenu'
            :key='index'
            :class="[currentIdx === index ? 'active' : '']"
            @click='goMenu(m, index)'
          >
            <span class='iconfont' :class='[m.icon]'></span>{{ m.name }}
          </li>
        </ul>
      </el-col>
      <el-col
        :span='19'
        class='right-warp'
        :style="{ minHeight: domHeight + 'px' }"
      >
        <div v-for='(c, index) in rightContent' :key='index'>
          <div
            class='title'
            @click='downFile(c)'
            style='color: #999;  font-size: 14px'
          >
            <span>
              <span class='icon-dark'
              ><el-icon><Download /></el-icon
              ></span>
              <span style='margin-left: 6px;'>{{ c.title }}</span>
            </span>
            <span v-if='c.time'>更新时间：{{ c.time }}</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import downInfo from './down'

const baseURL = process.env.VUE_APP_BASE_HELP_DOWN_HOST

const currentIdx = ref(0)
const domHeight = ref(0)
const leftMenu = ref([
  {
    id: 1,
    name: '政策文件',
    icon: 'icon-zhengcewenjian'
  },
  {
    id: 2,
    name: '申请表',
    icon: 'icon-shenqing'
  },
  {
    id: 3,
    name: '操作手册',
    icon: 'icon-caozuoshouce'
  }
])
const rightContent = ref(downInfo['1'])
const goMenu = (e, i) => {
  currentIdx.value = i
  const id = e.id
  rightContent.value = downInfo[id]
}
const downFile = (item) => {
  window.open(baseURL + item.downUrl, '_blank')
}
onMounted(() => {
  domHeight.value = document.documentElement.clientHeight - 200
})
</script>
<style lang='scss'>
@import '@/styles/icon.scss';

@mixin commonShadow {
  margin: 0 1%;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0px 0px 20px #e6e6e6;
  min-height: 800px;
}

.left-warp {
  @include commonShadow;

  ul {
    list-style: none;
    margin: 0;

    li {
      cursor: pointer;
      line-height: 45px;
      border-bottom: 1px #e2e2e2 solid;
      font-size: 16px;
      font-weight: 500;

      &:hover {
        color: #409eff;
      }

      span {
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }
}

.right-warp {
  @include commonShadow;

  div {
    .title {
      border-radius: 5px;
      border: 1px #e2e2e2 solid;
      padding: 5px 10px;
      cursor: pointer;
      font-weight: 500;
      line-height: 36px;
      margin-bottom: 15px;
      display: flex;
      justify-content: space-between;

      &:hover {
        color: #409eff;
        border: 1px #409eff solid;
      }

      .icon-dark {
        background: #409eff;
        color: #fff;
        padding: 0 3px;
        border-radius: 3px;
      }
    }

    .content {
      line-height: 36px;
    }
  }
}

.active {
  color: #409eff;
  font-weight: 500;
}
</style>
