<template>
  <div style='height: 100%' v-loading='loading'>
    <nesi-user-common title='详情内容'>
      <template #content>
        <div v-if='article.id'>
          <h2 style='text-align: center;padding: 24px 0'>{{ article.title }}</h2>
          <div class='publicSource'>
            来源：{{ article.source }}
            <text class='publicTime'>发布时间：{{ article.publishedAt }}</text>
          </div>
        </div>
        <div class='publicContent' v-html='content'></div>
      </template>
    </nesi-user-common>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, computed } from 'vue'
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import { getArticleInfoApi } from '@/api/home'
import { ElMessage } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()

const router = useRouter()
const route = useRoute()
const loading = ref(false)
const article = ref(false)
const minioUrl = computed(() => store.state.app.minioUrl)

// 提交表单
const fetchArticeInfo = async () => {
  try {
    loading.value = true
    console.log(route)
    const articeInfo = await getArticleInfoApi({ id: route.query.id })
    article.value = articeInfo
  } finally {
    loading.value = false
  }
}
// 文章信息
const content = computed(() => {
  const item = article.value
  if (!item.content) return ''
  let tempContent = item.content
  tempContent = tempContent.replace(
    /src\s*=\s*&quot;\s*\//g,
    'style=&quot;&quot; src=&quot;' + 'https://www.nnfwzl.com' + '/'
  )
  tempContent = tempContent.replace(
    /href\s*=\s*&quot;\s*\//g,
    'style=&quot;width: 97%;&quot; href=&quot;' + 'https://www.nnfwzl.com' + '/'
  )
  tempContent = tempContent.replace(/&gt;/g, '>')
  tempContent = tempContent.replace(/&lt;/g, '<')
  tempContent = tempContent.replace(/&quot;/g, '"')
  tempContent = tempContent.replace(/&amp;nbsp;/g, ' ')
  tempContent = tempContent.replace(
    /<table\s*width\s*=\s*"[0-9]*"\s*style\s*="width:\s*[0-9]*px;">/g,
    '<table width="852" style="width: 100%;">'
  )
  // https://www.nnfwzl.com/ueditor/upload/image/20220630/6379219713824060608629279.jpg
  tempContent = tempContent.replaceAll('https://www.nnfwzl.com', minioUrl.value)
  // console.log(tempContent, minioUrl.value)
  return tempContent
})
onMounted(() => {
  setTimeout(() => {
    fetchArticeInfo()
  }, 500)
})

</script>
<style lang='scss'>
.publicTitle {
  text-align: center;
  padding: 15px 0;
  font-size: 20px;
}

.publicSource {
  padding: 15px 20px;
  font-size: 12px;
  color: #999;
  display: flex;
  justify-content: space-between;
}

.publicContent {
  padding: 0 20px;
  font-size: 16px;
}
</style>
