<template>
  <div style='height: 100%'>
    <el-row justify='space-between'>
      <el-col :span='4' class='left-warp' :style="{height:domHeight+'px'}">
        <ul>
          <li v-for='(m,index) in leftMenu' :key='index' :class="[currentIdx===index?'active':'']"
              @click='goMenu(m,index)'>
            <span class='iconfont' :class='[m.icon]'></span>{{ m.name }}
          </li>
        </ul>
      </el-col>
      <el-col :span='19' class='right-warp' :style="{minHeight:domHeight+'px'}">
        <div v-for='(c,index) in rightContent' :key='index'>
          <div class='title'>{{ index + 1 }}、{{ c.title }}</div>
          <div class='content'>{{ c.solution }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import HelpInfo from './helpInfo'
import { useRouter } from 'vue-router'

const router = useRouter()

const currentIdx = ref(0)
const domHeight = ref(0)
const leftMenu = ref([
  {
    id: 1,
    name: '账号注册问题',
    icon: 'icon-adduser'
  },
  {
    id: 2,
    name: '系统设置问题',
    icon: 'icon-shezhi'
  },
  {
    id: 3,
    name: '挂牌问题',
    icon: 'icon-guapai'
  },
  {
    id: 4,
    name: '投诉咨询',
    icon: 'icon-guzhangtousu'
  }
])
const rightContent = ref(HelpInfo['1'])
const goMenu = (e, i) => {
  currentIdx.value = i
  if (e.id === 4) {
    router.push('/user/complaint')
  } else {
    const id = e.id
    rightContent.value = HelpInfo[id]
  }
}
onMounted(() => {
  domHeight.value = document.documentElement.clientHeight - 200
})
</script>
<style lang='scss'>
@import '@/styles/icon.scss';

@mixin commonShadow {
  margin: 0 1%;
  padding: 20px 30px;
  background: #fff;
  box-shadow: 0px 0px 20px #e6e6e6;
  min-height: 800px;
}

.left-warp {
  @include commonShadow;

  ul {
    list-style: none;
    margin: 0;

    li {
      cursor: pointer;
      line-height: 45px;
      border-bottom: 1px #e2e2e2 solid;
      font-size: 16px;
      font-weight: 500;

      &:hover {
        color: #409eff;
      }

      span {
        margin-right: 5px;
        font-weight: 500;
      }
    }
  }
}

.right-warp {
  @include commonShadow;

  div {
    margin: 0 10px;

    .title {
      color: #409eff;
      font-weight: 500;
      line-height: 36px;
      margin-top: 16px;
    }

    .content {
      line-height: 32px;
      color: #999;
      font-size: 14px;
    }
  }
}

.active {
  color: #409eff;
  font-weight: 500;
}
</style>
