export default {
  1: [
    {
      title: '个人注册时手机收不到验证码怎么办。',
      solution:
        '遇到该问题的，首先请确保输入的手机号码正确无误。其次请耐心等待，系统可能会出现延迟情况。'
    },
    {
      title: '账户能否设置头像、如何更改头像。',
      solution:
        '个人用户登录之后可在我的个人信息中点击头像位置，即可跳转到更换头像界面，然后只需按照正常流程上传头像即可。'
    },
    {
      title: '个人注册时能不能做实人认证、怎么做。',
      solution:
        '个人用户实人认证可在微信扫一扫页面中进行具体操作，目前PC端尚不支持实人认证。'
    },
    {
      title: '个人注册名字输错了怎么改。',
      solution:
        '非常抱歉，本平台要求个人用户进行实名注册，因此无法更改注册时的姓名。'
    }
  ],
  2: [
    {
      title: '看中房源后怎么联系挂牌人。',
      solution:
        '可在具体房源详情页预约申请、挂牌人同意预约后即可在我的预约申请出看见挂牌人的联系方式。'
    },
    {
      title: '如何在线签订租赁合同。',
      solution:
        '合同签订需要房东或者从业人员在我的合同管理进行发起，发起后房东或从业人员输入相关合同信息，并在我的合同确认里面确认并在线签字，然后合同其他人员会受到需要确认合同的短信通知 所有相关人员登录平台完成确认即可完成合同签订。'
    },
    {
      title: '地图找房如何操作',
      solution:
        '地图找房功能可在网站首页搜索框右侧点击进入，也可在租赁房源列表页与集中式公寓项目列表页搜索框右侧进入。'
    }
  ],
  3: [
    {
      title: '如何修改已经挂牌的房源（图片、价格）。',
      solution:
        '个人用户可在我的自主挂牌中选择变更信息，变更挂牌价格，也可以先下架后重新挂牌再修改。'
    },
    {
      title: '个人自主挂牌时图片无法上传（格式、大小不对）怎么办。',
      solution:
        'PC端用户请使用谷歌浏览器或者edge浏览器，其他情况下图片无法上传的请检查网络状况。'
    }
  ]
}
